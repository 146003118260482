<template>
  <div>
    <el-card>
      <el-steps :active="active" finish-status="success">
        <el-step title="步骤 1"></el-step>
        <el-step title="步骤 2"></el-step>
        <el-step title="步骤 3"></el-step>
        <el-step title="步骤 4"></el-step>
        <el-step title="步骤 5"></el-step>
        <el-step title="步骤 6"></el-step>
      </el-steps>
      <el-row :gutter="10" type="flex" style="margin-top: 20px" align="middle">
        <el-col :span="1">订单号:</el-col>
        <el-col :span="3">{{ orderInfo.orderNo }}</el-col>
        <el-col :span="2">支付方式:</el-col>
        <el-col :span="3">{{ orderInfo.tradeType }}</el-col>
        <el-col :span="2">支付类型:</el-col>
        <el-col :span="3">{{ orderInfo.backup2 }}</el-col>
        <el-col :span="1">交易号:</el-col>
        <el-col :span="3">{{ orderInfo.tradeNo }}</el-col>
        <el-col :span="6">
          <el-button type="primary" size="medium" style="float: right"
            >下载订单</el-button
          >
        </el-col>
      </el-row>
      <el-row :gutter="10" style="margin-top: 20px">
        <el-col :span="2">订单列表:</el-col>
        <el-col :span="22">
          <el-table
            stripe
            :max-height="500"
            :data="orderInfo.orderDetails"
            style="width: 100%"
          >
            <el-table-column label="商品图片" width="100">
              <template slot-scope="scope">
                <el-image
                  style="width: 60px; height: 60px"
                  :src="scope.row.productImg"
                  :preview-src-list="[scope.row.productImg]"
                  fit="contain"
                >
                  <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                  </div>
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="productName" label="商品名称" width="180">
            </el-table-column>
            <el-table-column prop="productNo" label="货号"> </el-table-column>
            <el-table-column prop="productBrand" label="产品品牌">
            </el-table-column>
            <el-table-column prop="productType" label="产品类型">
            </el-table-column>
            <el-table-column prop="productModel" label="型号">
            </el-table-column>
            <el-table-column prop="originalPrice" label="原价">
            </el-table-column>
            <el-table-column prop="purchasePrice" label="现价">
            </el-table-column>
            <el-table-column prop="quantity" label="数量"> </el-table-column>
            <el-table-column label="小计">
              <template slot-scope="scope">
                {{ scope.row.totalPrice | toThousandFilter }}
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>

      <el-row :gutter="10" type="flex" style="margin-top: 30px" align="middle">
        <el-col :span="1">收货人:</el-col>
        <el-col :span="3">{{ orderInfo.receiverName }}</el-col>
        <el-col :span="2">收货电话:</el-col>
        <el-col :span="3">{{ orderInfo.receiverPhone }}</el-col>
        <el-col :span="2">收货地址:</el-col>
        <el-col :span="10">{{ orderInfo.receiverAddress }}</el-col>
      </el-row>

      <el-row :gutter="10" type="flex" style="margin-top: 50px" align="middle">
        <el-col
          >公司/个人名称:
          <span style="padding-left: 20px">{{ orderInfo.userName }}</span>
        </el-col>
        <el-col style="text-align: right"
          >订单金额:
          <span class="money">
            ¥ {{ orderInfo.orderAmount | toThousandFilter }}</span
          ></el-col
        >
      </el-row>

      <el-row :gutter="10" type="flex" style="margin-top: 30px" align="middle">
        <el-col
          >客户备注:
          <span style="padding-left: 20px">{{ orderInfo.remark }}</span>
        </el-col>
        <el-col style="text-align: right"
          >订单金额:
          <span class="money">
            ¥ {{ orderInfo.orderAmount | toThousandFilter }}</span
          ></el-col
        >
      </el-row>

      <el-row :gutter="10" type="flex" style="margin-top: 30px" align="middle">
        <el-col
          >个人发票:
          <span style="padding-left: 20px">{{ orderInfo.remark }}</span>
        </el-col>
        <el-col style="text-align: right"
          >订单金额:
          <span class="money">
            ¥ {{ orderInfo.orderAmount | toThousandFilter }}</span
          ></el-col
        >
      </el-row>

      <el-row :gutter="10" type="flex" style="margin-top: 50px" align="middle">
      </el-row>
    </el-card>

    <el-card style="margin-top: 20px">
      <el-row>
        <el-col :span="12">操作历史:</el-col>
        <el-col :span="12">
          <el-row style="text-align: right">
            <el-button type="primary">修改订单</el-button>
            <el-button type="success">确认订单</el-button>
            <el-button type="info">取消订单</el-button>
          </el-row>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import { getOrderByNumber } from '@/api/order.js'
export default {
  data() {
    return {
      active: 1,
      orderNumber: '',
      orderInfo: {},
    }
  },
  created() {
    this.orderNumber = this.$route.params.orderNumber
    this.getOrderInfo()
  },
  methods: {
    async getOrderInfo() {
      var { data } = await getOrderByNumber(this.orderNumber)

      let orderInfo = { ...data }
      orderInfo.orderNo = '1313213213'
      orderInfo.backup2 = '1313213213'
      orderInfo.tradeNo = '1313213213'
      orderInfo.receiverName = '1313213213'
      orderInfo.receiverPhone = '1313213213'
      orderInfo.receiverAddress = '测试用地址'
      orderInfo.userName = 'Test用户'

      this.orderInfo = data
      console.dir(data)
    },
  },
  computed: {
    userHistoryStep() {
      let stepArr = []
      if (this.orderInfo.createTime) {
        stepArr.push({ time: this.orderInfo, remark: '用户创建订单' })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.image-slot {
  height: 60px;
  line-height: 60px;
  text-align: center;
}
.has-gutter {
  background-color: #dddddd;
}
.money {
  padding-left: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #8c50ff;
}
</style>
